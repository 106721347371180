import { render, staticRenderFns } from "./DepotInfo.vue?vue&type=template&id=aa0dfaca&scoped=true"
import script from "./DepotInfo.vue?vue&type=script&lang=js"
export * from "./DepotInfo.vue?vue&type=script&lang=js"
import style0 from "./DepotInfo.vue?vue&type=style&index=0&id=aa0dfaca&prod&lang=scss&scoped=content"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa0dfaca",
  null
  
)

export default component.exports