<template>
	<el-drawer
		:show-close="false"
		:with-header="false"
		custom-class="serviceMap-realRun"
		@opened="open"
		:visible.sync="drawer"
		direction="btt"
		:before-close="handleClose"
		:modal="false"
		:size="200"
	>
		<el-tabs v-model="active">
			<el-tab-pane label="实时班次信息" name="0">
				<real-run-main ref="realRunMain" :formData="formData"></real-run-main>
			</el-tab-pane>
			<el-tab-pane label="车辆状态信息" name="1">
				<jy-table max-height="595" :data="vlist">
					<jy-table-column label="车牌号" prop="vhNo"></jy-table-column>
					<jy-table-column label="车辆状态" prop="onlineName"></jy-table-column>
					<jy-table-column label="定位时间" prop="time"></jy-table-column>
					<jy-table-column label="定位位置" prop="address"></jy-table-column>
				</jy-table>
			</el-tab-pane>
		</el-tabs>
	</el-drawer>
</template>

<script>
import realRunMain from "@/components/pages/admin/realTime/realRunMain.vue";

import gcoord from "gcoord";
import { getCompleteAddressByPoint } from "@/common/js/BMapUtil";
export default {
	provide() {
		return {
			siteList: this.siteList,
			positionVehicleList: this.positionVehicleList,
			isAnnular: this.getType
		};
	},
	filters: {
		getAddress(row) {
			getCompleteAddressByPoint(row.lng, row.lat).then(rs => {
				return rs.address;
			});
		}
	},
	methods: {
		async getVStateList() {
			let restRecord = 0,
				vlist = [],
				pageIndex = 1;
			do {
				const result = await this.$http.post("/realtime/getRTVehicleInfo", {
					pageIndex,
					pageSize: 200,
					orgId: this.formData.routeId
				});
				const { total, list } = result.detail;
				for (let index = 0; index < list.length; index++) {
					const v = list[index];
					const [{ lng, lat, time }] = v.devicePosList;
					v.time = this.$util.fixedMacTime(time);
					const p = gcoord.transform([lng, lat], gcoord.WGS84, gcoord.BD09);
					v.lng = p[0];
					v.lat = p[1];
					v.onlineName = v.online == 1 ? "在线" : "离线";
					v.address = "";
				}
				vlist = vlist.concat(list);
				restRecord = total - vlist.length;
				pageIndex++;
			} while (restRecord > 0);
			this.vlist = vlist;

			for (let index = 0; index < this.vlist.length; index++) {
				const v = this.vlist[index];
				const rs = await getCompleteAddressByPoint(v.lng, v.lat);
				v.address = rs.address;
			}
		},
		getType() {
			return this.isAnnular;
		},
		init(data, checked) {
			if (checked) {
				this.formData.routeId = data.id;
				this.formData.routeNa = data.text;
				this.drawer = true;

				this.getSiteList();
				this.getVStateList();
			} else {
				this.drawer = false;
			}
		},
		open() {
			this.getRuningVehicle();
			this.$refs.realRunMain.getData();
			this.$refs.realRunMain.getWaitVehicleList();
			if (this.intervalId) {
				clearInterval(this.intervalId);
				this.intervalId = null;
			}
			this.intervalId = setInterval(() => {
				console.error("ee");
				this.getRuningVehicle();
				this.$refs.realRunMain.getData();
				this.$refs.realRunMain.getWaitVehicleList();
			}, 10 * 1000);
		},
		handleClose() {
			this.drawer = false;
			if (this.intervalId) {
				clearInterval(this.intervalId);
				this.intervalId = null;
			}
		},
		getRuningVehicle() {
			let url = "/waybillActual/queryRealtimeVehicleList";
			let params = {
				...this.formData
			};
			this.$http.post(url, params).then(({ detail }) => {
				console.log(detail);
				let runingVehicleList = detail;
				this.queryVehiclePosition(runingVehicleList.map(item => item.vehicleId).join(","));
			});
		},
		queryVehiclePosition(ids) {
			let url = "/waybillActual/queryVehiclePosition";
			let params = {
				vehicleIds: ids
			};
			this.$http.post(url, params).then(({ detail }) => {
				this.positionVehicleList.splice(0);
				if (detail) {
					detail.forEach(item => {
						let newLngLat = this.$util.wgs84tobd09(Number(item.lng), Number(item.lat));
						item.lng = newLngLat[0];
						item.lat = newLngLat[1];
					});
					this.positionVehicleList.push(...detail);
				}
			});
		},
		getSiteList() {
			let url = "/site/info/queryByRouteId";
			let params = {
				routeId: this.formData.routeId
			};
			this.$http.post(url, params).then(({ detail }) => {
				this.siteList.splice(0);
				if (detail) {
					let list = detail.map(item => {
						let obj = {
							...item,
							...item.routeSite,
							...item.siteInfo
						};
						let newLngLat = this.$util.wgs84tobd09(Number(obj.lng), Number(obj.lat));
						obj.lng = newLngLat[0];
						obj.lat = newLngLat[1];
						return obj;
					});
					this.siteList.push(...list);
				}
			});
		}
	},
	data() {
		return {
			drawer: false,

			active: "0",
			formData: {
				routeId: "",
				routeNa: "",
				oDate: new Date().Format("yyyy-MM-dd")
			},

			intervalId: "",

			positionVehicleList: [],
			siteList: [],
			vlist: []
		};
	},
	components: {
		realRunMain
	}
};
</script>

<style lang="scss" scoped>
.el-tabs {
	padding: 0 20px;
}
</style>
