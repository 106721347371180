import { render, staticRenderFns } from "./vehiclesTotals.vue?vue&type=template&id=945c5c64&scoped=true"
import script from "./vehiclesTotals.vue?vue&type=script&lang=js"
export * from "./vehiclesTotals.vue?vue&type=script&lang=js"
import style0 from "./vehiclesTotals.vue?vue&type=style&index=0&id=945c5c64&prod&lang=scss&scoped=totalBox"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "945c5c64",
  null
  
)

export default component.exports