<template>
	<div>
		<el-divider></el-divider>
		<jy-table max-height="595" ref="table" class="panel" :data="alarmList">
			<jy-table-column type="index" label="序号" align="center"></jy-table-column>
			<jy-table-column label="报警类型" prop="alarmType">
				<template v-slot="{ row }">
					{{ alarmTypeMap[row.alarmType] }}
				</template>
			</jy-table-column>
			<jy-table-column prop="alarmBeginTime" label="报警时间"></jy-table-column>
			<jy-table-column :minWidth="120" label="报警名称" prop="alarmName"></jy-table-column>
		</jy-table>
		<!-- <el-divider></el-divider>
		<div class="search">
			<el-button size="mini" type="primary" @click="navToHandle">报警数据</el-button>
		</div>
		<el-divider></el-divider> -->
	</div>
</template>

<script>
// import { getCompleteAddressByPoint } from "@/common/js/BMapUtil";
import dayjs from "dayjs";

export default {
	destroyed() {
		this.alarmList = [];
	},
	methods: {
		navToHandle() {
			// this.$router.push({
			//     name: "intelligent",
			//     params: {
			//         vehicleNo: this.vehicleNo
			//     }
			// });
		},
		formatter(row, column, cellValue) {
			return cellValue.$FixedMacTime().split(" ")[1];
		},
		init(vehicleNo) {
			this.vehicleNo = vehicleNo;
			const url = "/crrc-palm-bus/safetyManagement/list";
			const params = {
				pageNum: 1,
				pageSize: 99,
				alarmBeginTime: dayjs().format("YYYY-MM-DD 00:00:00"),
				alarmEndTime: dayjs().format("YYYY-MM-DD 23:59:59"),
				vehicleNo: this.vehicleNo
			};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.alarmList = res?.records || [];
			});
		}
	},
	data() {
		return {
			alarmList: [],
			vehicleNo: "",
			alarmTypeMap: {
				1: "DSM报警",
				2: "电子围栏报警",
				3: "车辆报警",
				4: "电池报警"
			}
		};
	}
};
</script>
<style lang="scss" scoped>
.panel {
	max-height: 600px;
	min-height: 500px;
	overflow-y: auto;
}
.search {
	text-align: center;
}
</style>
