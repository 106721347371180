<template>
	<div class="plannedShift">
		<jy-query class="query" ref="form" :model="formData">
			<jy-query-item prop="vehicleNo" label="车辆" span="1.5">
				<el-select v-model="formData.vehicleNo" placeholder="请选择" clearable>
					<el-option v-for="item in vehicleList" :key="item.key" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="driverNa" label="司机" span="1.5">
				<el-select v-model="formData.driverNa" placeholder="请选择" clearable>
					<el-option v-for="item in driverList" :key="item.key" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheduleSchedulingPlannedShiftList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')" v-if="btnexist('scheduleSchedulingPlannedShiftReset')"
					>重置</el-button
				>
				<!-- <el-row>
                </el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" @click="addUdate()" v-if="btnexist('scheduleSchedulingPlannedShiftInsert')">新增排班</el-button>
			</template>
		</jy-query>

		<jy-table max-height="595" ref="jyTable" height="480" :data="dataShow" class="table">
			<!-- <jy-table-column type="index" width="50" label="序号" fixed="left"></jy-table-column> -->
			<jy-table-column prop="shiftNum" label="班次" fixed></jy-table-column>
			<jy-table-column prop="dTime" label="计划发车时间" fixed></jy-table-column>
			<jy-table-column prop="oTime" label="计划结束时间"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="计划发车车辆"></jy-table-column>
			<jy-table-column prop="driverNa" label="计划发车司机"></jy-table-column>
			<jy-table-column prop="sSiteNa" label="起始站"></jy-table-column>
			<jy-table-column prop="eSiteNa" label="终点站"></jy-table-column>
			<jy-table-column label="线路方向">
				<template slot-scope="scope">{{ scope.row.schemeT | schemeT }}</template>
			</jy-table-column>
			<jy-table-column label="营运状态">
				<template slot-scope="scope">{{ scope.row.operationT | operationT }}</template>
			</jy-table-column>
			<jy-table-column label="班次类型">
				<template slot-scope="scope">{{ scope.row.type | type }}</template>
			</jy-table-column>
			<jy-table-column prop="remark" label="备注"></jy-table-column>
			<jy-operate v-bind:list="operationList"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
			:count="5"
		></jy-pagination>
		<add-shift ref="npm" @updateList="update"></add-shift>
	</div>
</template>
<script>
import addShift from "@/components/pages/admin/scheduling/plannedShift/addShift";
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			schemeId: "",
			routeInfo: "",
			formData: {
				routeId: "",
				vehicleNo: "",
				driverNa: "",
				oDate: ""
			},
			vehicleList: [],
			list: [],
			driverList: [],
			operationList: [],
			rotationList: [],
			monthShift: [],
			isInit: true,
			// 分页
			pageIndex: 1,
			total: 0,
			pageSize: 10,
			pageNum: 1,
			totalPageData: [],
			dataShow: []
		};
	},
	mixins: [btnMixins],
	components: {
		addShift
	},
	filters: {
		isActive(val) {
			if (val == 0) {
				return "无效";
			} else if (val == 1) {
				return "有效";
			}
		},
		reason(val) {
			let v = "";
			switch (val) {
				case "0":
					v = "正常";
					break;
				case "1":
					v = "砍班";
					break;
				case "2":
					v = "脱班";
					break;
				case "3":
					v = "调整";
					break;
			}
			return v;
		},
		type(val) {
			if (val == 1) {
				return "普通班";
			} else if (val == 2) {
				return "加班";
			} else if (val == 3) {
				return "夜班";
			} else {
				return val;
			}
		},
		schemeT(val) {
			if (val == 1) {
				return "上行";
			} else if (val == 2) {
				return "下行";
			}
		},
		operationT(val) {
			if (val == 0) {
				return "营运";
			} else if (val == 1) {
				return "非营运";
			}
		}
	},
	async created() {
		this.getButtons("scheduleSchedulingPlannedShift");
		this.getDate();
	},
	methods: {
		init(options) {
			this.routeInfo = options;
			this.formData.routeId = options.id;
			this.getList();
			this.seOperation();
			this.getVehicles();
			this.getDriver();
		},
		getDate() {
			let now = new Date();
			this.formData.oDate = now.Format("yyyy-MM-dd");
		},
		getDriver() {
			let url = "/base/driver/getDriversByOrgId";
			let option = {
				orgId: this.routeInfo.id
			};
			this.$http.post(url, option).then(({ detail }) => {
				this.driverList = [];
				detail.map(item => {
					let v = {
						label: item.cName,
						value: item.cName,
						key: item.driverId
					};
					this.driverList.push(v);
				});
			});
		},
		getVehicles() {
			let url = "/base/vehicle/getVehiclesByOrgId";
			let option = {
				orgId: this.routeInfo.id
			};
			this.$http.post(url, option).then(({ detail }) => {
				this.vehicleList = [];
				detail.map(item => {
					let v = {
						label: item.plateNum,
						value: item.plateNum,
						key: item.vehicleId
					};
					this.vehicleList.push(v);
				});
			});
		},
		seOperation() {
			this.operationList = [
				{
					name: "发车通知",
					fun: item => {
						this.sedCar(item);
					},
					isShow: () => {
						return true;
					}
				},
				{
					name: "编辑",
					fun: item => {
						this.addUdate(item);
					},
					isShow: () => {
						return true;
					}
				},
				{
					name: "复制",
					fun: item => {
						this.copy(item);
					},
					isShow: () => {
						return true;
					}
				},
				{
					name: "删除",
					fun: item => {
						this.del(item);
					},
					isShow: () => {
						return true;
					}
				}
			];
		},
		getList() {
			let option = {
				...this.formData
			};
			this.pageIndex = 1;
			let url = "/waybillPlan/queryPage";
			this.$http.post(url, option).then(({ detail }) => {
				let num = 1;
				detail.map(item => {
					item.shiftNum = "班次" + num;
					num++;
				});
				this.list = detail;
				this.total = this.list.length;
				this.calcPageData();
			});
			console.log(this.list);
		},
		// 查询表格
		oncheck() {
			this.isInit = true;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.getDate();
			this.oncheck();
		},
		// 分页
		calcPageData() {
			this.dataShow = [];
			this.totalPageData = [];
			if (this.list.length > 1) {
				this.pageNum = Math.ceil(this.list.length / this.pageSize) || 1;
			}
			for (let i = 0; i < this.total; i++) {
				this.totalPageData[i] = this.list.slice(this.pageSize * i, this.pageSize * (i + 1));
			}
			this.dataShow = this.totalPageData[this.pageIndex - 1];
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.calcPageData();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.dataShow = this.totalPageData[val - 1];
		},
		addUdate(option) {
			if (option) {
				this.$refs.npm.init(option, "update");
			} else {
				let option = {
					routeId: this.routeInfo.id,
					routeNa: this.routeInfo.text,
					orgId: this.routeInfo.porgId,
					oDate: this.$util.getNowDate(),
					schemeId: this.schemeId
				};
				this.$refs.npm.init(option, "add");
			}
		},
		update(id) {
			if (id) {
				this.schemeId = id;
			}
			this.isInit = false;
			this.getList();
		},
		sedCar(item) {
			this.isInit = false;
			console.log(item);
			let url = "/waybillPlan/depNotice";
			let option = {
				waybillId: item.waybillId
			};
			this.$http.post(url, option).then(res => {
				this.$message({
					message: res.msg,
					type: "success"
				});
				this.getList();
			});
		},
		copy(item) {
			this.isInit = false;
			let url = "/waybillPlan/add";
			let option = {
				...item,
				schemeId: this.schemeId
			};
			this.$http.post(url, option).then(res => {
				this.$message({
					message: res.msg,
					type: "success"
				});
				this.getList();
			});
		},
		del(item) {
			this.isInit = false;
			this.$confirm("是否删除该班次?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let url = "/waybillPlan/del";
					let option = {
						waybillId: item.waybillId
					};
					this.$http.post(url, option).then(res => {
						this.$message({
							message: res.msg,
							type: "success"
						});
						this.getList();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		}
	}
};
</script>
<style lang="scss" scoped="plannedShift">
.plannedShift {
	max-height: 680px;
	.query {
		margin-top: 10px;
	}
	.table {
		font-size: 12px;
	}
}
</style>
