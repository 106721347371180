<template>
	<div class="basic_wrapper">
		<div class="basic_header">
			<div class="car-info-title">
				<span class="icon"></span>
				<span>{{ dataObj.vehicleNo }}</span>
			</div>
		</div>
		<div class="car-info-content">
			<div class="car-info-detail">
				<div class="car-info-time">
					<div class="gauge">
						<div>
							<div>{{ dataObj.spd }}</div>
							km/h
						</div>
					</div>
					<ul>
						<li>时间：{{ dataObj.dateTime }}</li>
						<li>里程：{{ dataObj.mileage }}km</li>
						<li>电量：{{ dataObj.soc }}</li>
						<li>地址：{{ dataObj.siteNa }}</li>
					</ul>
				</div>

				<hr style="border-width: 1px; color: #00b0ea; margin-top: 20px; width: calc(100% - 130px)" />
				<div class="car-info-route">
					<ul class="left-route">
						<li>归属机构：{{ dataObj.orgName }}</li>
						<li>起始站：{{ dataObj.startSiteNa }}</li>
						<li>当前站：{{ dataObj.siteNa }}</li>
						<li>载客数：{{ dataObj.passengerNum }}</li>
					</ul>
					<ul class="right-route">
						<li>线路：{{ dataObj.routeName }}</li>
						<li>终点站：{{ dataObj.endSiteNa }}</li>
						<li>下一站：{{ dataObj.backSiteNa }}</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getCompleteAddressByPoint } from "@/common/js/BMapUtil";

import gcoord from "gcoord";

// import bus_background from "@/assets/serviceMap/rightAside/bus_background.png";
import bus_background from "@/assets/serviceMap/rightAside/title.png";
import driver_background from "@/assets/serviceMap/rightAside/driver_background.png";
export default {
	methods: {
		init(options) {
			this.carInfo = options;
			this.vehicleNo = options.plateNum;
			this.lng = options.lng;
			this.lat = options.lat;
			const [lng, lat] = gcoord.transform([this.lng, this.lat], gcoord.WGS84, gcoord.BD09);
			getCompleteAddressByPoint(lng, lat).then(rs => {
				this.address = rs.address;
			});
			this.time = this.$util.fixedMacTime(options.time);
			this.getData();
			// this.speed = options.spd;
			// this.orgName = options.orgName;
		},
		update(data) {
			this.vehicleNo = data.vhNo;
			this.lng = data.lng;
			this.lat = data.lat;
			this.speed = data.speed;
			this.time = this.$util.fixedMacTime(data.time);
			const [lng, lat] = gcoord.transform([this.lng, this.lat], gcoord.WGS84, gcoord.BD09);
			this.orgName = data.orgNa;
			getCompleteAddressByPoint(lng, lat).then(rs => {
				this.address = rs.address;
			});
		},
		itemClick(one) {
			if (one.canClick === undefined ? this.canClick : one.canClick) {
				this.$emit("itemClick", one);
			}
		},

		getData() {
			const url = `/crrc-palm-bus/gis/line/getVehicleInfo`;
			const params = {
				vehicleId: this.carInfo.vehicleId
			};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.dataObj = res || {};
			});
		}
	},
	data() {
		return {
			carInfo: { vehicleId: "" },
			dataObj: {
				spd: "",
				soc: "",
				vehicleNo: "",
				dateTime: "",
				mileage: "",
				siteNa: "",
				orgName: "",
				startSiteNa: "",
				passengerNum: "",
				routeName: "",
				endSiteNa: "",
				backSiteNa: ""
			},
			vehicleNo: "",
			customizeNo: "-",
			orgName: "",
			speed: "",
			soc: "",
			pCount: "",
			signal: 3,
			lng: "",
			lat: "",
			address: "",
			time: "",
			driverName: "-",
			phoneNo: "-",
			heartRate: "-",
			minbp: "-",
			maxbp: "-",
			rankScore: "-",
			countScore: "-",
			bus_background,
			driver_background,
			detailList: [
				{
					icon: require("@/assets/serviceMap/rightAside/serverMils.png"),
					info: "营运里程(公里)",
					num: "682",
					name: "routeNum",
					canClick: true
				},
				{
					icon: require("@/assets/serviceMap/rightAside/no_serverMils.png"),
					info: "非营运里程(公里)",
					num: "50",
					name: "routeLen",
					canClick: true
				},
				{
					icon: require("@/assets/serviceMap/rightAside/totalShift.png"),
					info: "计划趟次",
					num: "50",
					name: "totalShift",
					canClick: false
				},
				{
					icon: require("@/assets/serviceMap/rightAside/relShift.png"),
					info: "实际趟次",
					num: "30",
					name: "exShift",
					canClick: false
				},
				{
					icon: require("@/assets/serviceMap/rightAside/lackShift.png"),
					info: "缺班趟次",
					num: "20",
					name: "lackShift",
					canClick: false
				},
				{
					icon: require("@/assets/home/png/line.png"),
					info: "准点趟次",
					num: "20",
					name: "ontimes",
					canClick: false
				}
			]
		};
	},
	components: {},
	computed: {
		getYM() {
			return new Date().Format("yyyy年MM月");
		}
	}
};
</script>
<style lang="scss" scoped>
.basic_wrapper {
	padding: 20px;
	width: 100%;
	min-height: 500px;
	max-height: 600px;
	overflow-y: auto;
	.car-info-title {
		height: 45px;
		font-size: 18px;
		color: white;
		font-weight: bold;
		background: linear-gradient(270deg, #2864cd 0%, #0582eb 100%);
		// background: url("./images/title.png") no-repeat 0 0 / 100% 100% transparent;
		display: flex;
		align-items: center;
		padding-left: 20px;
		.icon {
			width: 24px;
			height: 24px;
			background: url("./images/car_icon.png") no-repeat;
			background-size: 100% 100%;
			margin-right: 15px;
		}
	}

	.car-info-content {
		// border: 1px solid #46738f;
		border-top: none;
		// background: #042440;
		position: absolute;
		// right: 0;
		top: 80px;
		// width: 277px;
		font-size: 16px;

		.car-info-detail {
			.car-info-time {
				display: flex;
				align-items: center;
				ul {
					flex: 1;
					li {
						padding: 5px 0px 10px 30px;
					}
				}
				.gauge {
					width: 105px;
					height: 123px;
					background: url("./images/gauge.png") no-repeat 0 0 / 100% 100% transparent;
					margin: 0 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					& > div > div {
						margin-top: 20px;
						font-size: 22px;
						font-weight: 600;
					}
				}
			}
			.border-bottom {
				border-bottom: 1px solid #00b0ea;
			}
			.car-info-route {
				display: flex;
				padding: 20px 15px;
				.left-route {
					width: 600px;
				}
				.right-route {
					margin-left: 50px;
				}
				& > ul {
					flex: 1;
					li {
						padding: 10px 0px 10px 0px;
					}
				}
			}
		}
	}
}
</style>
