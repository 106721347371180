<template>
	<div class="basic_wrapper">
		<div>
			<el-form :model="diaform" ref="dialogForm">
				<el-row>
					<el-col :span="8">
						<el-form-item label="机构名称:" prop="orgNa">
							<label>{{ diaform.orgNa }}</label>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="上级机构:" prop="pOrgNa">
							<label>{{ diaform.pOrgNa }}</label>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="机构类型:" prop="type">
							<label>{{ diaform.type }}</label>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="省份:" prop="province">
							<label>{{ diaform.province }}</label>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="城市:" prop="bCity">
							<label>{{ diaform.bCity }}</label>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="detail">
			<span class="title">数据概览</span>
			<el-divider></el-divider>
			<div>
				<div
					class="detail_item"
					v-for="one in detailList"
					v-bind:key="one.id"
					v-bind:class="{ hover: one.canClick === undefined ? canClick : one.canClick }"
					v-on:click="itemClick(one)"
				>
					<slot :data="one">
						<img :src="one.icon" class="img" />
					</slot>
					<div class="text">
						<div class="number1" v-if="one.canClick == true">{{ one.num }}</div>
						<div class="number" v-else>{{ one.num }}</div>
						<div>{{ one.info }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="detail">
			<span class="title">运营状态</span>
			<el-divider></el-divider>
			<div>
				<operation-status></operation-status>
			</div>
		</div>
		<line-sitedetail ref="sitedetail"></line-sitedetail>
		<add-shift ref="shift"></add-shift>
	</div>
</template>

<script>
import OperationStatus from "./components/OperationStatus.vue";
import { btnMixins } from "@/common/js/button.mixin";
import addShift from "@/components/pages/admin/scheduling/plannedShift/addShift";
import LineSitedetail from "@/components/pages/admin/resourcesAllocation/lineSiteQuery/lineSitedetail";
import AddShift from "../../../../components/pages/admin/scheduling/plannedShift/addShift.vue";
export default {
	data() {
		return {
			diaform: {
				orgNa: "",
				pOrgNa: "",
				type: "",
				province: "",
				bCity: ""
			},
			routeInfo: "",
			downSizes: [],
			total: 0,
			detailList: [
				{
					icon: require("@/assets/home/png/routeTotal.png"),
					info: "线路总数(条)",
					num: "0",
					name: "routeNum",
					canClick: true
				},
				{
					icon: require("@/assets/home/png/line.png"),
					info: "线路总长(公里)",
					num: "0",
					name: "routeLen",
					canClick: true
				},
				{
					icon: require("@/assets/home/png/car.png"),
					info: "车辆总数(辆)",
					num: "0",
					name: "vehicleNum",
					canClick: true
				},
				{
					icon: require("@/assets/home/png/car.png"),
					info: "司机总数(个)",
					num: "50",
					name: "totalDriver",
					canClick: true
				},
				{
					icon: require("@/assets/home/png/site.png"),
					info: "计划趟次",
					num: "50",
					name: "totalShift",
					canClick: false
				},
				{
					icon: require("@/assets/home/png/line.png"),
					info: "实际趟次",
					num: "30",
					name: "exShift",
					canClick: false
				},
				{
					icon: require("@/assets/home/png/site.png"),
					info: "缺班趟次",
					num: "20",
					name: "lackShift",
					canClick: false
				},
				{
					icon: require("@/assets/home/png/line.png"),
					info: "准点趟次",
					num: "20",
					name: "ontimes",
					canClick: false
				}
			]
		};
	},
	components: {
		OperationStatus,
		LineSitedetail,
		addShift,
		AddShift
	},
	mixins: [btnMixins],
	methods: {
		init(options) {
			this.routeInfo = options;
			this.getOrgInfo(options);
			this.getList();
		},
		getOrgInfo(options) {
			let url = "/sys/org/pageQuery";
			let params = {
				sysOrg: {
					orgNa: options.text,
					pOrgId: "",
					pOrgNa: ""
				},
				pageIndex: 1,
				pageSize: 10
			};
			this.$http.post(url, params).then(({ detail }) => {
				let list = detail.list.map(item => {
					if (item.type == 0) {
						item.type = "机构";
					} else if (item.type == 1) {
						item.type = "车队";
					} else {
						item.type = "线路";
					}
					return {
						...item
					};
				});
				this.diaform = list[0];
			});
		},
		getList() {
			let url = "/home/basicView";
			this.$http.post(url).then(({ detail }) => {
				// this.$store.commit("setBasicView",detail)
				for (let i in detail) {
					this.detailList.forEach(item => {
						if (item.name == i) {
							item.num = detail[i];
						}
					});
				}
			});
		},
		itemClick(one) {
			if (one.canClick === undefined ? this.canClick : one.canClick) {
				this.$emit("itemClick", one);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.el-divider--horizontal {
	margin: 10px 0;
}
.basic_wrapper {
	padding: 0 20px;
	width: 100%;
	max-height: 510px;
	overflow-y: auto;
	.siteDetail {
		margin-left: 10px;
	}
	.detail {
		box-shadow: #dcdcdc 0px 0px 7px;
		display: flex;
		flex-wrap: wrap;
		margin-top: 16px;
		margin-bottom: 20px;
		.title {
			margin-left: 10px;
			padding-top: 10px;
			font-size: 18px;
			font-weight: bold;
		}
		.detail_item {
			// flex: 1;
			// display: flex;
			padding: 10px;
			width: 155px;
			display: inline-flex;
			.img {
				height: 45px;
			}
			.text {
				padding-left: 10px;
				// display: flex;
				flex-direction: column;
				.number {
					flex: 1;
					font-weight: bold;
					font-size: 18px;
					color: #5a5a5a;
					+ div {
						color: #b3b8ce;
						font-size: 14px;
						font-weight: 600;
					}
				}
				.number1 {
					flex: 1;
					font-weight: bold;
					font-size: 18px;
					color: #04b5fc;
					cursor: pointer;
					+ div {
						color: #b3b8ce;
						font-size: 14px;
						font-weight: 600;
					}
				}
			}
		}
	}
	.foot {
		margin-bottom: 20px;
		height: 100px;
	}
}
</style>
