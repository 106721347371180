<template>
	<transition name="rightAside" v-on:after-enter="afterEnter">
		<div v-if="visible" class="ra-wrapper">
			<div class="ra-nav">
				<div class="ra-nav-item" :class="{ 'is-active': active === '1' }" @click="tabClick('1')">
					<img :src="active === '1' ? icons.icon_basic_select : icons.icon_basic" />
					<span class="tab_label_title">基础信息</span>
				</div>
				<div class="ra-nav-item" :class="{ 'is-active': active === '3' }" @click="tabClick('3')">
					<img :src="active === '3' ? icons.icon_video_select : icons.icon_video" />
					<span class="tab_label_title">视频对讲</span>
				</div>
				<div class="ra-nav-item" :class="{ 'is-active': active === '6' }" @click="tabClick('6')">
					<img :src="active === '6' ? icons.icon_track_select : icons.icon_track" />
					<span class="tab_label_title">今日轨迹</span>
				</div>
				<div class="ra-nav-item" :class="{ 'is-active': active === '7' }" @click="tabClick('7')">
					<img :src="active === '7' ? icons.icon_alarm_select : icons.icon_alarm" />
					<span class="tab_label_title">今日报警</span>
				</div>
				<!-- <div class="ra-nav-item" :class="{ 'is-active': active === '8' }" @click="tabClick('8')">
					<img :src="active === '8' ? icons.icon_cap_select : icons.icon_cap" />
					<span class="tab_label_title">排班信息</span>
				</div>
				<div class="ra-nav-item" :class="{ 'is-active': active === '9' }" @click="tabClick('9')">
					<img :src="active === '9' ? icons.icon_cap_select : icons.icon_cap" />
					<span class="tab_label_title">文本下发</span>
				</div>
				<div class="ra-nav-item" :class="{ 'is-active': active === '10' }" @click="tabClick('10')">
					<img :src="active === '10' ? icons.icon_roadBill_select : icons.icon_roadBill" />
					<span class="tab_label_title">路单明细</span>
				</div> -->
				<!-- <div class="ra-nav-item" :class="{ 'is-active': active === '11' }" @click="tabClick('11')">
                    <img :src="active === '11' ? icons.icon_serviceTotal_select : icons.icon_serviceTotal" />
                    <span class="tab_label_title">运营统计</span>
                </div> -->
			</div>
			<div class="ra-content">
				<basic ref="basic" @itemClick="itemClick" key="1" v-show="active === '1'"></basic>
				<r-video ref="video" key="3" v-show="active === '3'"></r-video>
				<track-replay ref="track" key="6" v-show="active === '6'" :vehicleId="vehicleId"></track-replay>
				<alarm ref="alarm" key="7" v-show="active === '7'"></alarm>
				<!-- <class-list ref="classes" key="8" v-show="active === '8'"></class-list>
				<sendtxt ref="txt" key="9" v-show="active === '9'"></sendtxt>
				<road-bill ref="bill" key="10" v-show="active === '10'"></road-bill>
				<serve-total ref="servetotal" key="11" v-show="active === '11'"></serve-total> -->
			</div>

			<div size="mini" class="button_close" @click="close"><i class="el-icon-close"></i></div>
		</div>
	</transition>
</template>

<script>
import basic_normal from "@/assets/serviceMap/rightAside/basic_normal.svg";
import basic_select from "@/assets/serviceMap/rightAside/basic_selected.svg";
import video_normal from "@/assets/serviceMap/rightAside/video_normal.svg";
import video_selected from "@/assets/serviceMap/rightAside/video_selected.svg";
import cap_normal from "@/assets/serviceMap/rightAside/video_normal.svg";
import cap_selected from "@/assets/serviceMap/rightAside/video_selected.svg";
import dispatch_normal from "@/assets/serviceMap/rightAside/dispatch_normal.svg";
import dispatch_selected from "@/assets/serviceMap/rightAside/dispatch_selected.svg";
import track_normal from "@/assets/serviceMap/rightAside/track_normal.svg";
import track_selected from "@/assets/serviceMap/rightAside/track_selected.svg";
import alarm_normal from "@/assets/serviceMap/rightAside/alarm_normal.svg";
import alarm_selected from "@/assets/serviceMap/rightAside/alarm_selected.svg";
import roadBill_select from "@/assets/serviceMap/rightAside/roadBill.svg";
import roadBill_normal from "@/assets/serviceMap/rightAside/roadBill_out.svg";
import serviceTotal_select from "@/assets/serviceMap/rightAside/serviceTotal.svg";
import serviceTotal_normal from "@/assets/serviceMap/rightAside/serviceTotal_out.svg";

import Basic from "./BasicNew.vue";
import Alarm from "./Alarm.vue";
import RVideo from "./Video2.vue";
import TrackReplay from "./TrackReplay.vue";
// import ClassList from "./ClassList.vue";
// import Sendtxt from "./Sendtxt.vue";
// import roadBill from "./roadBill.vue";
// import serveTotal from "./serverTotal.vue";

export default {
	destroyed() {
		this.visible = false;
		this.active = "1";
	},
	methods: {
		close() {
			this.visible = false;
			this.active = "1";
		},
		afterEnter() {},
		update(data) {
			if (data.vhNo === this.vehicleNo) {
				if (this.$refs.basic) {
					this.$refs.basic.update(data);
				}
			}
		},
		init(options) {
			// 更换车辆重置订阅
			this.active = "1";

			const { plateNum: vehicleNo, vehicleId } = options;

			this.vehicleNo = vehicleNo;
			this.vehicleId = vehicleId;

			this.visible = true;

			this.$http
				.post(
					"/base/device/queryByVehicleId",
					{
						vehicleId: this.vehicleId
					},
					{
						type: "format"
					}
				)
				.then(res => {
					const dlist = res.detail;
					this.$refs.video && this.$refs.video.init(dlist, vehicleNo);
					this.$refs.track && this.$refs.track.init(dlist, options);
				});

			this.$nextTick(() => {
				console.log(this.$refs.basic, this.$refs.alarm, "-------------");
				this.$refs.basic.init(options);
				this.$refs.alarm.init(vehicleNo);

				// this.$refs.classes.init(vehicleNo);
				// this.$refs.txt.init(vehicleId);
				// this.$refs.bill.init(options);
				// this.$refs.servetotal.init(options);
			});
		},
		tabClick(item) {
			// if (item !== "6") {
			//     this.active = item;
			// } else {
			//     // 轨迹回放
			// }
			this.active = item;
		},
		itemClick(val) {
			if (val.info == "营运里程(公里)" || val.info == "非营运里程(公里)") {
				this.active = "11";
			}
		}
	},
	data() {
		return {
			visible: false,
			vehicleId: "",
			vehicleNo: "",

			active: "1",
			deviceIds: "",

			typeList: [],

			icons: {
				icon_basic: basic_normal,
				icon_basic_select: basic_select,
				icon_video: video_normal,
				icon_video_select: video_selected,
				icon_cap: cap_normal,
				icon_cap_select: cap_selected,
				icon_dispatch: dispatch_normal,
				icon_dispatch_select: dispatch_selected,
				icon_track: track_normal,
				icon_track_select: track_selected,
				icon_alarm: alarm_normal,
				icon_alarm_select: alarm_selected,
				icon_roadBill: roadBill_normal,
				icon_roadBill_select: roadBill_select,
				icon_serviceTotal: serviceTotal_normal,
				icon_serviceTotal_select: serviceTotal_select
			}
		};
	},
	components: {
		Basic,
		Alarm,
		RVideo,
		TrackReplay
		// ClassList,
		// Sendtxt,
		// roadBill,
		// serveTotal
	}
};
</script>
<style lang="scss" scoped="ra-wrapper">
.rightAside-enter,
.rightAside-leave-to {
	opacity: 0;
	transform: translateX(550px);
}

.rightAside-enter-active,
.rightAside-leave-active {
	transition: all 0.5s ease;
}

.content-enter,
.content-leave-to {
	opacity: 0;
}

.content-enter-active,
.content-leave-active {
	transition: opacity 0.2s ease;
}
.ra-wrapper {
	position: absolute;
	right: 50px;
	top: 50px;
	width: 730px;
	background-color: white;
	display: flex;
	& > .ra-nav {
		display: flex;
		flex-direction: column;
		width: 80px;
		.ra-nav-item {
			display: flex;
			flex-direction: column;
			padding-top: 16px;
			width: 80px;
			height: 80px;
			text-align: center;
			color: #c8c8c8;
			cursor: pointer;
			img {
				width: 36px;
				height: 30px;
				margin: 0 auto;
			}
			.tab_label_title {
				margin-top: 5px;
				font-size: 12px;
				line-height: 16px;
			}
		}
		& > .is-active {
			background-color: #3572f2;
			color: #fff;
		}
	}
	& > .ra-content {
		box-shadow: -4px 0 5px #ebebeb;
		width: 650px;
		padding: 10px 15px;
	}

	.button_close {
		position: absolute;
		width: 30px;
		height: 30px;
		top: 5px;
		right: 0px;
		// background-color: #fff;
		// border: 1px solid #ddd;
		cursor: pointer;
		display: table-cell;
		text-align: center;
		& > i {
			font-size: 25px;
		}
		& :hover {
			color: #3572f2;
		}
	}
}
</style>
