import { render, staticRenderFns } from "./routeDetail.vue?vue&type=template&id=213d4586&scoped=true"
import script from "./routeDetail.vue?vue&type=script&lang=js"
export * from "./routeDetail.vue?vue&type=script&lang=js"
import style0 from "./routeDetail.vue?vue&type=style&index=0&id=213d4586&prod&lang=scss&scoped=road_info_management"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "213d4586",
  null
  
)

export default component.exports