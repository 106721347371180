<template>
	<div class="road_info_management">
		<jy-query class="query" ref="form" :model="attendanceForm">
			<jy-query-item label="开始时间:" prop="startTime" span="1.5">
				<el-date-picker
					v-model="attendanceForm.startTime"
					value-format="yyyy-MM-dd"
					:picker-options="startTime"
					type="date"
					placeholder="选择日期"
				></el-date-picker>
			</jy-query-item>
			<jy-query-item label="结束时间:" prop="endTime" span="1.5">
				<el-date-picker
					v-model="attendanceForm.endTime"
					value-format="yyyy-MM-dd"
					:picker-options="endTime"
					type="date"
					placeholder="选择日期"
				></el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="dataList" class="table" height="480">
			<jy-table-column prop="plateNum" label="打卡车辆" width="100"></jy-table-column>
			<jy-table-column prop="rTime" label="考勤时间" min-width="100"></jy-table-column>
			<jy-table-column prop="orgNa" label="线路" width="95"></jy-table-column>
			<jy-table-column label="考勤结果">
				<template slot-scope="scope">{{ scope.row.aType | res }}</template>
			</jy-table-column>
		</jy-table>
		<jy-pagination
			class="foot"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
			:count="5"
		></jy-pagination>
	</div>
</template>
<script>
export default {
	data() {
		return {
			btnMenuId: "scheduleRoadBillInfoManagement",
			butList: [],
			dataList: [],
			pageIndex: 1,
			pageSize: 10,
			total: 0,
			attendanceForm: {
				startTime: "",
				endTime: "",
				driverIds: [],
				routeIds: [],
				employeeId: ""
			},
			routeList: [],
			routeInfo: "",
			startTime: {
				disabledDate: time => {
					if (this.attendanceForm.endTime) {
						return time.getTime() >= new Date(this.attendanceForm.endTime).getTime();
					} else {
						return time.getTime() > Date.now();
					}
				}
			},
			endTime: {
				disabledDate: time => {
					if (this.attendanceForm.startTime) {
						return time.getTime() > Date.now() || time.getTime() < new Date(this.attendanceForm.startTime).getTime() - 8.64e7;
					} else {
						return time.getTime() > Date.now();
					}
				}
			}
		};
	},
	filters: {
		aType(val) {
			if (val == 1) {
				return "上班";
			} else if (val == 2) {
				return "下班";
			} else if (val == 3) {
				return "签到";
			} else if (val == 4) {
				return "签退";
			} else if (val == 5) {
				return "例检";
			}
		},
		res(val) {
			if (val == 1) {
				return "上班成功";
			} else if (val == 2) {
				return "下班成功";
			} else if (val == 3) {
				return "签到成功";
			} else if (val == 4) {
				return "签退成功";
			} else if (val == 5) {
				return "例检成功";
			}
		}
	},
	methods: {
		init(options) {
			this.routeInfo = options;
			let ids = [];
			ids.push(options.id);
			this.attendanceForm.driverIds = ids;
			this.getList();
		},
		getList() {
			let url = "/attendance/detail/queryPage";
			let option = {
				...this.attendanceForm,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			this.$http.post(url, option).then(({ detail }) => {
				this.dataList = detail.list;
				this.total = detail.total;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getList();
		}
	}
};
</script>
<style lang="scss" scoped="road_info_management">
.road_info_management {
	max-height: 680px;
	.query {
		margin-top: 10px;
	}
	.table {
		font-size: 12px;
	}
}
</style>
