<template>
	<div class="driver_detail">
		<jy-table max-height="595" ref="jyTable" :data="dataList" class="table">
			<jy-table-column prop="cName" label="司机姓名" width="100"></jy-table-column>
			<jy-table-column prop="wNum" label="司机工号" min-width="100"></jy-table-column>
			<jy-table-column prop="cwaNo" label="考勤卡号" width="95"></jy-table-column>
			<jy-table-column prop="eDate" label="入职时间" width="95"></jy-table-column>
			<jy-table-column prop="owaNo" label="离职时间" width="95"></jy-table-column>
			<jy-table-column label="供职状态" min-width="100">
				<template slot-scope="scope">{{ scope.row.sState | serType }}</template>
			</jy-table-column>
		</jy-table>
		<div class="total">
			<span>司机数: 10个</span>
			<span>在职: 10个</span>
			<span>离职: 0个</span>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			btnMenuId: "scheduleRoadBillInfoManagement",
			butList: [],
			dataList: [],
			pageIndex: 1,
			pageSize: 10,
			total: 0,
			formData: {
				oDate: "",
				routeIds: [],
				customNo: "",
				vehicleNo: "",
				driverNa: "",
				eState: "",
				verify: ""
			},
			routeList: [],
			routeInfo: ""
		};
	},
	filters: {
		serType(val) {
			if (val == "0") {
				return "离职";
			} else if (val == "1") {
				return "在职";
			}
		}
	},
	created() {},
	methods: {
		init(options) {
			this.routeInfo = options;
			this.getList();
		},
		getList() {
			this.dataList = [
				{
					cName: "张三",
					wNum: "z-001",
					cwaNo: "z-001",
					eDate: "2021-04-14",
					oDate: "",
					sState: "1"
				},
				{
					cName: "张三",
					wNum: "z-001",
					cwaNo: "z-001",
					eDate: "2021-04-14",
					oDate: "",
					sState: "1"
				},
				{
					cName: "张三",
					wNum: "z-001",
					cwaNo: "z-001",
					eDate: "2021-04-14",
					oDate: "",
					sState: "1"
				},
				{
					cName: "张三",
					wNum: "z-001",
					cwaNo: "z-001",
					eDate: "2021-04-14",
					oDate: "",
					sState: "1"
				}
			];
		}
	}
};
</script>
<style lang="scss" scoped="driver_detail">
.driver_detail {
	max-height: 600px;
	overflow-y: auto;
	.query {
		width: 710px;
		margin-top: 10px;
	}
	.table {
		font-size: 12px;
	}
	.total {
		flex: 1;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		height: 54px;
		background: inherit;
		// background-color: rgba(215, 215, 215, 1);
		padding: 20px;
		font-size: initial;
	}
}
</style>
