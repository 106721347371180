<template>
    <div class="totalBox">
        <jy-query class="query" ref="checkForm" :model="formInline">
            <jy-query-item label="查询方式" prop="queryType" span="1.5">
                <el-radio-group v-model="formInline.queryType">
                    <el-radio label="1">月</el-radio>
                    <el-radio label="0">年</el-radio>
                  </el-radio-group>
            </jy-query-item>
            <jy-query-item label="" prop="queryYear" v-if="formInline.queryType == '0'&&isshow" span="1.5">
                <el-date-picker v-model="formInline.queryYear" :clearable="false" type="year" value-format="yyyy"  placeholder="选择年"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="" prop="queryMonth"  v-if="formInline.queryType == '1'&&isshow" span="1.5">
                <el-date-picker v-model="formInline.queryMonth" :clearable="false" type="month" value-format="yyyy-MM"  placeholder="选择月"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
            </template>
        </jy-query>
        <el-table ref="jyTable" class="table" :data="dataList" :summary-method="getSummaries" show-summary>
            <jy-table-column type="index" label="序号" width="60" fixed align="center"></jy-table-column>
            <jy-table-column prop="date" label="日期" min-width="100"  v-if="formInline.queryType == '1'"></jy-table-column>
            <jy-table-column prop="month" label="月份" min-width="100"  v-if="formInline.queryType == '0'"></jy-table-column>
            <jy-table-column prop="kilometre" label="营运里程" min-width="100"></jy-table-column>
            <jy-table-column prop="outKilometre" label="非营运里程" min-width="100"></jy-table-column>
            <jy-table-column prop="planShift" label="计划班次" min-width="100"></jy-table-column>
            <jy-table-column prop="relShift" label="实际趟次" min-width="100" ></jy-table-column>
            <jy-table-column prop="lackShift" label="缺班趟次" min-width="100" ></jy-table-column>
            <jy-table-column prop="ontimes" label="准点趟次" min-width="100" ></jy-table-column>
            <jy-table-column prop="exShiftRate" label="计划完成率" min-width="100" ></jy-table-column>
            <jy-table-column prop="lackShiftRate" label="缺班率" min-width="100" ></jy-table-column>
            <jy-table-column prop="ontimesRate" label="发车准点率" min-width="100" ></jy-table-column>
            <jy-table-column prop="mPeakCompletionRate" label="早高峰完成率" min-width="100" ></jy-table-column>
            <jy-table-column prop="mPeakPunctualityRate" label="早高峰准点率" min-width="100" ></jy-table-column>
            <jy-table-column prop="nPeakCompletionRate" label="晚高峰完成率" min-width="100" ></jy-table-column>
            <jy-table-column prop="nPeakPunctualityRate" label="晚高峰准点率" min-width="100" ></jy-table-column>
            <jy-table-column prop="firstShiftCompletionRate" label="首班准点率" min-width="100" ></jy-table-column>
            <jy-table-column prop="endShiftCompletionRate" label="末班准点率" min-width="100" ></jy-table-column>
        </el-table>
        <!-- <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination> -->
    </div>
</template>
<script>
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            routeInfo: '',
            isshow:true,
            formInline: {
                routeId:'',
                customNo: '',
                vehicleIds: '',
                vehicleNames: '',
                queryType:'1',
                queryYear: '',
                queryMonth:'',
            },
            type:'0',
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [],
            routeList: [],
            carList:[],
            propList:[],
            routeIds:[],

            // btnMenuId:'scheduleStatisticalAnalysisGPStotal',
        }
    },
    mixins:[btnMixins],
    async created() {
        this.type = '0';
        this.getNowData()
        this.getList()
    },
    methods: {
        init(options) {
            this.routeInfo = options
        },
        getSummaries(param) {
            console.log(param)
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                let type = ''
                let num = data[0][column.property]
                if(index === 0) {
                    sums[index] = '合计';
                    return;
                }
                if(num != undefined) {
                    if(index > 1 && index < 4) {
                        num = num.substring(0,num.length-2)
                    } else {
                        num = num.substring(0,num.length-1)
                    }
                    type = Object.prototype.toString.call(Number(num))
                } else {
                    type = Object.prototype.toString.call(data[0][column.property])
                }
                console.log(type.slice(8,-1))
                if(type && type.slice(8,-1)=='Number'){
                    let total = 0
                    data.forEach(item=>{
                        let val = item[column.property]
                        if(index > 1 && index < 4) {
                            val = val.substring(0,val.length-2)
                        } else {
                            val = val.substring(0,val.length-1)
                        }
                        total = total+Number(val)
                    })
                    if(isNaN(total)) {
                        sums[index] =''
                    } else {
                        if(index > 1 && index < 4) {
                            total = total +"km"
                        } else if(index >= 4 && index < 8) {
                            total = total +"次"
                        } else if(index >= 8) {
                            total = total +"%"
                        }
                        sums[index] = total
                    }
                }else{
                    sums[index] =''
                }
            });
            return sums
        },
        getNowData(){
            let date = new Date();
            let year = date.getFullYear()
            let month = date.getMonth()
            if (month == 0) {
                year -= 1
                month = 12
            }
            month = month < 10 ? ('0' + month) : month
            this.formInline.queryYear = new Date().Format('yyyy')
            this.formInline.queryMonth = year + '-' + month
        },
        getList() {
            this.dataList = [{
                date:"4-1",
                month:"1月",
                kilometre:"1500km",
                outKilometre:"20km",
                planShift:"72",
                relShift:"22次",
                lackShift:"49次",
                ontimes:"32次",
                exShiftRate:"25%",
                lackShiftRate:"25%",
                ontimesRate:"28%",
                mPeakCompletionRate:"30%",
                mPeakPunctualityRate:"50%",
                nPeakCompletionRate:"40%",
                nPeakPunctualityRate:"20%",
                firstShiftCompletionRate:"40%",
                endShiftCompletionRate:"10%"
            },{
                date:"4-2",
                month:"2月",
                kilometre:"1500km",
                outKilometre:"20km",
                planShift:"72次",
                relShift:"22次",
                lackShift:"49次",
                ontimes:"32次",
                exShiftRate:"25%",
                lackShiftRate:"25%",
                ontimesRate:"15%",
                mPeakCompletionRate:"20%",
                mPeakPunctualityRate:"10%",
                nPeakCompletionRate:"20%",
                nPeakPunctualityRate:"20%",
                firstShiftCompletionRate:"10%",
                endShiftCompletionRate:"20%"
            },{
                date:"4-3",
                month:"3月",
                kilometre:"1500km",
                outKilometre:"20km",
                planShift:"72次",
                relShift:"22次",
                lackShift:"49次",
                ontimes:"32次",
                exShiftRate:"15%",
                lackShiftRate:"25%",
                ontimesRate:"28%",
                mPeakCompletionRate:"10%",
                mPeakPunctualityRate:"20%",
                nPeakCompletionRate:"30%",
                nPeakPunctualityRate:"20%",
                firstShiftCompletionRate:"10%",
                endShiftCompletionRate:"20%"
            },{
                date:"4-4",
                month:"4月",
                kilometre:"1500km",
                outKilometre:"20km",
                planShift:"72次",
                relShift:"22次",
                lackShift:"49次",
                ontimes:"32次",
                exShiftRate:"15%",
                lackShiftRate:"25%",
                ontimesRate:"28%",
                mPeakCompletionRate:"10%",
                mPeakPunctualityRate:"20%",
                nPeakCompletionRate:"10%",
                nPeakPunctualityRate:"20%",
                firstShiftCompletionRate:"10%",
                endShiftCompletionRate:"10%"
            }]
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
    }
}

</script>
<style lang="scss" scoped="totalBox">
.totalBox {
    max-height: 680px;
    .query {
        margin-top:10px;
    }
    .table {
        margin-top:10px;
        font-size: 12px;
    }
}
</style>
